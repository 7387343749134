import React from "react";
import { useNavigate } from "react-router-dom";

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { MdPeopleOutline, MdDeleteOutline, MdElectricBolt } from "react-icons/md";
import { GrShieldSecurity } from "react-icons/gr";
import { PiIdentificationBadge } from "react-icons/pi";

import SettingsLinks from "./SettingsLinks";

import DeleteDialog from "@medoczen/core/DeleteDialog";
import { IoIosNotifications } from "react-icons/io";

// Define sections with titles, links, paths, and icons directly included
const sections = [
  {
    title: "Connected experiences",
    links: [
      {
        name: "Family Care",
        path: "/family-members",
        icon: <MdPeopleOutline size={24} />,
      },
    ],
  },
  {
    title: "Account settings",
    links: [
      {
        name: "Personal Details",
        path: "/settings/personal-details",
        icon: <PiIdentificationBadge size={24} />,
      },
      {
        name: "Notification settings",
        path: "/settings/notification-settings",
        icon: <IoIosNotifications size={24} />,
      },

      {
        name: "Password and security",
        path: "/password-and-security",
        icon: <GrShieldSecurity size={24} />,
      },
      {
        name: "Medoczen Pro",
        path: "/settings/medoczen-pro",
        icon: <MdElectricBolt size={24} />,
      },
    ],
  },
];

const SettingsSection = ({ title, links }) => (
  <div className="w-full">
    <span className="text-[15px] font-semibold text-primary">{title}</span>
    <div className="w-full h-fit px-[1rem] py-[1rem] rounded-[6px] bg-[#96969a0e] text-[#000000a8] flex flex-col gap-[1.2rem] mx-auto">
      {links.map((link) => (
        <SettingsLinks key={link.name} name={link.name} icon={link.icon} path={link.path} />
      ))}
    </div>
  </div>
);

function Settings(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <HeaderWrapper left_nav title="Settings" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        {sections.map((section) => (
          <SettingsSection key={section.title} title={section.title} links={section.links} />
        ))}

        <div className="w-full">
          <span className="text-[15px] font-semibold text-primary"></span>
          <div className="w-full h-fit px-[1rem] py-[1rem] rounded-[6px] bg-[#96969a0e] text-[#000000a8] flex flex-col gap-[1.2rem] mx-auto">
            <div onClick={() => setOpen(true)}>
              <SettingsLinks
                key={""}
                name={"Delete account"}
                icon={<MdDeleteOutline size={24} />}
                path={""}
              />
            </div>
          </div>
        </div>
      </div>

      <DeleteDialog
        open={open}
        setOpen={setOpen}
        content={"Are you sure you want to delete your account ?"}
        action
        data
      />
    </>
  );
}

export default Settings;
