import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { Avatar, Divider } from "@mui/material";
import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LuClock3 } from "react-icons/lu";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { IoVideocam } from "react-icons/io5";
import { HiHome } from "react-icons/hi";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

export default function AppointmentTab({ item }) {
  const navigate = useNavigate();
  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ country_code }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(country_code);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img
        src={flagSrc}
        className="absolute top-[1px] left-[1px] w-[1rem] object-cover h-[.8rem] rounded-[2px] bg-white p-[1px]"
        alt="flag"
      />
    );
  };

  const status = item?.status;

  function HandleClick() {
    // sessionStorage.setItem("appointment_details", JSON.stringify(item));
    navigate(`/appointments/${item.id}`);
  }

  return (
    <div
      onClick={HandleClick}
      key={item.id}
      className={`mx-auto w-full ${
        status === "completed" ? "bg-gray-100 text-[black]" : "bg-primary text-white"
      } rounded-lg shadow-md overflow-hidden`}
    >
      <div className={`flex items-center  p-2   `}>
        <div className="relative">
          <Avatar
            alt={item?.doctor?.full_name}
            src={item?.doctor?.selfie}
            variant="square"
            sx={{ width: 62, height: 62, borderRadius: 1 }}
            className="border-2 border-white"
          />
          <CountryFlag country_code={item?.doctor?.country_code} />
        </div>
        <div className="ml-4 flex items-start">
          <div>
            <h2 className=" text-[1rem] font-[600]">{item?.doctor?.full_name}</h2>
            <p className="text-[.75rem] ">{item?.doctor?.specialties[0]?.name}</p>
          </div>
          <div className="ml-8 pt-[2px]">
            {item?.type === "video" && (
              <button aria-label="video call" className=" bg-blue-600 p-1 rounded-md">
                <IoVideocam size={20} color="white" className="p-0 m-0" />
              </button>
            )}
            {item?.type === "in-home" && (
              <button aria-label="in-home" className=" bg-blue-600 p-1 rounded-md">
                <HiHome size={20} color="white" className="p-0 m-0" />
              </button>
            )}
            {item?.type === "chat" && (
              <button aria-label="chat" className=" bg-blue-600 p-1 rounded-md">
                <BiSolidMessageRoundedDetail size={20} color="white" className="p-0 m-0" />
              </button>
            )}
          </div>
        </div>

        <div className="ml-auto flex flex-col h-full justify-between gap-4   ">
          {item?.status === "completed" && (
            <button className="text-green-500 px-[3px]    py-[3px] text-[.65rem] bg-green-100 rounded-[3px]">
              {item?.status}
            </button>
          )}
          {item?.status === "started" && (
            <button className="text-white px-2    py-[3px] text-[.65rem] bg-blue-500 rounded-[3px] ">
              {item?.status}
            </button>
          )}

          {item?.status === "pending" && (
            <button className="text-white px-2    py-[3px] text-[.65rem] bg-orange-500 rounded-[3px] ">
              {item?.status}
            </button>
          )}

          {item?.status === "canceled" && (
            <button className="text-white px-2    py-[3px] text-[.65rem] bg-green-100 rounded-[3px] ">
              {item?.status}
            </button>
          )}
          <p className="text-[1.25rem] ">£{item?.doctor?.schedule?.rate}/hr</p>
        </div>
      </div>

      <div className="p-2 h-full flex w-full justify-center items-center ">
        <div
          className={`flex-1 flex justify-between max-w-[95%]  text-[.8rem] ${
            status === "completed" ? "text-black  bg-[#00000021]" : " text-[#ffffff] bg-[#00000021]"
          } p-3 rounded-lg`}
        >
          <div className="flex items-center gap-2 ">
            <HiOutlineCalendarDays size={20} />
            <span className="pt-[1px]">
              {item?.date ? format(new Date(item?.date), "EEE, dd MMM") : ""}
            </span>
          </div>
          <Divider
            orientation="vertical"
            sx={{
              height: 20,
              borderColor: status === "completed" ? "black" : "white",
              borderWidth: "1px",
            }}
            // variant="middle"
          />
          <div className="flex items-center gap-2 ">
            <LuClock3 size={20} />
            <span className="pt-[1px]">
              {(() => {
                if (item?.time) {
                  const [hours, minutes] = item.time.split(":"); // Extract hours and minutes
                  const startTime = `${hours}:${minutes}`;
                  const endMinutes = parseInt(minutes) + 30; // Add 30 minutes
                  const endTime =
                    endMinutes >= 60
                      ? `${(parseInt(hours) + 1) % 24}:${String(endMinutes % 60).padStart(2, "0")}`
                      : `${hours}:${String(endMinutes).padStart(2, "0")}`;
                  return `${startTime} - ${endTime}`;
                }
                return ""; // Handle case where item?.time is undefined
              })()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

// <Link to={`/appointments/${item.id}`} key={item.id} className="relative w-full h-auto p-4 rounded-[3px] bg-lightCream flex items-center shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
// <div className="flex flex-col items-center w-16 mr-4">
//   <img src={item?.patient?.selfie} alt="" className="w-16 h-16 rounded-full border-4 border-gray-200 shadow-sm" />
//   <CountryFlag country_code={item?.patient?.country_code} className="mt-2" />
// </div>
// <div className="flex-grow flex flex-col justify-between">
//   <h1 className="text-xl font-semibold text-gray-800">{item?.patient?.full_name}</h1>
//   <div className="flex items-center text-gray-600">
//     <GiConfirmed size={16} />
//     <span className="text-base font-medium">Confirmed</span>
//     <span className="ml-2 flex items-center justify-center px-2 py-1 h-6 rounded text-xs text-white bg-green-500">
//       {item?.type}
//     </span>
//   </div>
//   <div className="text-sm text-gray-500 flex items-center">
//     <MdViewTimeline size={14} />
//     <span>{item?.time} - {new Date(item?.date).toLocaleDateString()}</span>
//   </div>
// </div>
// <div className='text-gray-600 flex flex-col items-center' ><GiTwoCoins size={30} /> <span className='font-[500]'>£{item?.doctor?.schedule?.rate}/hr</span></div>
// <div className="absolute right-0 top-0 px-3 py-1 text-xs text-white  bg-orange-500">
//   {item?.status}
// </div>
// </Link>
