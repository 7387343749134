import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { Avatar, Divider, Skeleton } from "@mui/material";
import {
  getAppointments,
  getUpcomingAppointment,
  selectUpcomingAppointments,
} from "pages/Appointments/AppointmentsList/store/AppointmentSlice";
import React, { useEffect, useState } from "react";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";

import { HiHome } from "react-icons/hi";
import { HiOutlineCalendarDays } from "react-icons/hi2";

import { IoVideocam } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppointmentTab from "pages/Appointments/AppointmentsList/AppointmentTab";
function SkeletonComponent() {
  return (
    <div className="w-full h-[155px] px-[10px] py-[1rem] rounded-[6px] bg-[#96969a0e]  flex items-center gap-[1rem] shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
      <div className="">
        <Skeleton width={130} height={200} />
      </div>

      <div className="flex flex-col gap-2">
        <Skeleton width={122} height={30} />
        <Skeleton width={122} height={15} />
        <Skeleton width={122} height={15} />
        <Skeleton width={122} height={15} />
      </div>
    </div>
  );
}

export default function UpcomingAppointment() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [item, setItem] = useState({});

  useEffect(() => {
    setLoading(true);
    dispatch(getUpcomingAppointment()).then((res) => {
      // console.log("res", res);
      setItem(res.payload);

      setLoading(false);
    });
  }, [dispatch]);

  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ country_code }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(country_code);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img src={flagSrc} className="absolute top-[2px] left-[2px] w-[18px] h-[12px]" alt="flag" />
    );
  };

  const status = item?.status;

  return (
    <>
      {loading ? (
        <SkeletonComponent />
      ) : (
        <>
          <Link to={`/appointments/${item.id}`} key={item.id}>
            <AppointmentTab item={item} />
          </Link>
        </>
      )}
    </>
  );
}
