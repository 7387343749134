import React, { useState, useEffect } from "react";

import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "@medoczen/mock_db/axiosConfig";

import SplashScreen from "./pages/SplashScreen/SplashScreen";
import SignIn from "./pages/Auth/SignIn/SignIn";
import SignUp from "./pages/Auth/SignUp/SignUp";
import ConsentsAgreement from "./pages/Auth/SignUp/Consents&Agreements/ConsentsAgreement";

import ForgetPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Verification from "./pages/Auth/Verification/Verification";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import BottomNav from "@medoczen/core/BottomNavigation/BottomNavigation";
import HelpSupport from "./pages/Help-Support/HelpSupport";
import MedicineReminder from "./pages/Medicines/MedicineReminder/MedicineReminder";
import LabReports from "./pages/LabReport/LabReports";
import Payment from "./pages/Payments/Payment/Payment";
import Confirmation from "./pages/Payments/Confirmation/Confirmation";

import Settings from "./pages/Settings/Settings";
import PersonalDetails from "./pages/Settings/Personal-details/PersonalDetails";


import HealthAssessment from "./pages/Health-assessment/HealthAssessment";
import QuestionnaireType from "pages/Health-assessment/questionnaire/QuestionnaireType";
import Questionnaire from "pages/Health-assessment/questionnaire/Questionnaire";
import Answers from "pages/Health-assessment/answers/Answers";

import DoctorSpecialties from "./pages/Appointments/DoctorSpecialties/DoctorSpecialties";
import DoctorsList from "./pages/Appointments/DoctorsList/DoctorsList";
import DoctorProfile from "./pages/Appointments/DoctorProfile/DoctorProfile";
import BookAppointment from "./pages/Appointments/BookAppointment/BookAppointment";
import AppointmentList from "./pages/Appointments/AppointmentsList/AppointmentList";

import AddCard from "./pages/Payments/AddCard/AddCard";
import Members from "./pages/Members/Members";
import ChatTab from "./pages/Chats/Chat/ChatTab";
import VideoCall from "./pages/Chats/VideoCall/VideoCall";
import Dashboard from "./pages/Dashboard/Dashboard";
import Paymentmethod from "./pages/Payments/PaymentMethod/PaymentMethod";
import AddMedicineReminder from "./pages/Medicines/AddMedicineReminder/AddMedicineReminder";
import { getRefreshToken, getToken } from "@medoczen/core/MDZsessions";

import PrescribedMedicines from "pages/Medicines/PrescribedMedicines/PrescribedMedicines";

import FAQ from "pages/Help-Support/FAQ/FAQ";
import DocotorsFilter from "pages/Appointments/DoctorsList/DoctorsFilter";
import MedicinesList from "pages/Medicines/MedicinesList/MedicinesList";
import { resetGlobalState } from "./store";
import AddFamilyMembers from "pages/Members/AddFamilyMembers";
import DetailedDescription from "pages/Health-assessment/DetailedDescription";
import AppointmentDetails from "pages/Appointments/AppointmentsList/AppointmentDetails";
import AddReview from "pages/Appointments/AppointmentsList/reviews/AddReview";
import PasswordSecurity from "pages/Settings/Password&Security/PasswordSecurity";
import ChangePassword from "pages/Settings/Password&Security/ChangePassword/ChangePassword";
import authServiceInstance from "@services/jwtService/jwtService";
import StripePayment from "pages/Payments/StripePayment/StripePayment";
import ToastMessage from "@medoczen/core/ToastMessage/ToastMessage";
import MedoczenPro from "pages/Settings/Medoczen-pro/MedoczenPro";
import PrescriptionList from "pages/Medicines/Presciptions/PrescriptionList";
import Feedbacks from "pages/Help-Support/Feedback/Feedback";
import UserGuide from "pages/Help-Support/UserGuide/UserGuide";
import TermPolicies from "pages/Help-Support/TermPolicies/TermPolicies";
import TroubleShootGuide from "pages/Help-Support/TroubleShootGuide/TroubleShootGuide";
import NotificationSettings from "pages/Settings/NotificationSettings/NotificationSettings";
import NotificationSettingsDetails from "pages/Settings/NotificationSettings/NotificationSettingsDetails";


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = getToken(); // Call getToken to get the current value

  // Use a state to manage the visibility of the BottomNav dynamically
  const [showBottomNav, setShowBottomNav] = useState(false);

  // Update showBottomNav based on the current path
  useEffect(() => {
    // Normalize the current path by removing trailing slash (if present)
    const normalizedPath = location.pathname.endsWith("/")
      ? location.pathname.slice(0, -1)
      : location.pathname;

    // Define paths where the BottomNav should be shown
    const includedPaths = [
      "/dashboard",
      "/settings",

      "/medicine-reminder",

      "/video-call",
      "/faq",
      "/prescribed-medicines",
      "/medicines-list",
      "/appointments",
      "/prescription"



    ];

    // Check if the current path is one of the included paths
    const shouldShowBottomNav = includedPaths.includes(normalizedPath);

    // Determine the visibility based on token presence and path
    setShowBottomNav(token && shouldShowBottomNav);

    // If a token exists and the user is on the signin page or paths that are typically for unauthenticated users, navigate to the dashboard.
    if (token && (normalizedPath === "/signin" || normalizedPath === "/")) {
      navigate("/dashboard");
    } else if (!token) {
      const authPaths = [
        "/signin",
        "/signup",
        "/consents-and-agreements",
        "/forgot-password",
        "/reset-password",
        "/verification"
      ];


      // If there's no token and the user is not on an auth path, navigate to signin
      if (!authPaths.includes(normalizedPath)) {

        const refreshToken = getRefreshToken()
        if (!refreshToken) {
          navigate('/signin')
        } else {
          authServiceInstance.refreshToken()
        }

      }

    }

    // Dispatch resetGlobalState on path changes
    dispatch(resetGlobalState());
  }, [token, location.pathname, navigate, dispatch]);

  // Add this useEffect to reset state on route changes
  useEffect(() => {
    // Dispatch the resetGlobalState action or any other specific reset actions
    dispatch(resetGlobalState());
    // This effect should run every time the pathname changes
  }, [location.pathname, dispatch]);



  return (
    <div className="w-full h-fit ">
      <div className={`w-full h-full ${showBottomNav ? "mb-[4rem]" : "mb-0"} `}>
        <Routes>
          <Route path="/" element={<SplashScreen navigate={navigate} />} />
          <Route path="/signin" element={<SignIn navigate={navigate} />} />
          <Route path="/signup" element={<SignUp navigate={navigate} />} />
          <Route
            path="/consents-and-agreements"
            element={<ConsentsAgreement navigate={navigate} />}
          />

          <Route
            path="/forgot-password"
            element={<ForgetPassword navigate={navigate} />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword navigate={navigate} />}
          />
          <Route
            path="/verification"
            element={<Verification navigate={navigate} />}
          />
          <Route
            path="/appointments"
            element={<AppointmentList navigate={navigate} />}

          />
          <Route
            path="/appointments/:appointmentId"
            element={<AppointmentDetails navigate={navigate} />}
          />
          <Route path="/settings" element={<Settings navigate={navigate} />} />
          <Route
            path="/help-support"
            element={<HelpSupport navigate={navigate} />}
          />
          <Route
            path="/medicine-reminder"
            element={<MedicineReminder navigate={navigate} />}
          />

          <Route
            path="/lab-reports"
            element={<LabReports navigate={navigate} />}
          />
          <Route path="/payment" element={<Payment navigate={navigate} />} />
          <Route
            path="/confirmation"
            element={<Confirmation navigate={navigate} />}
          />

          <Route
            path="/doctor-specialties"
            element={<DoctorSpecialties navigate={navigate} />}
          />
          <Route
            path="/doctors-list"
            element={<DoctorsList navigate={navigate} />}
          />
          <Route
            path="/doctor-profile/:doctorId"
            element={<DoctorProfile navigate={navigate} />}
          />
          <Route
            path="/book-appointment/:doctorId"
            element={<BookAppointment navigate={navigate} />}
          />
          <Route
            path="/add-review"
            element={<AddReview navigate={navigate} />}
          />
          <Route
            path="/health-assessment"
            element={<HealthAssessment navigate={navigate} />}
          />
          <Route
            path="/health-assessment/detailed-description"
            element={<DetailedDescription navigate={navigate} />}
          />
          <Route
            path="/health-assessment/questionnaire-type"
            element={<QuestionnaireType navigate={navigate} />}
          />
          <Route
            path="/health-assessment/questionnaire"
            element={<Questionnaire navigate={navigate} />}
          />
          <Route
            path="/health-assessment/answers"
            element={<Answers navigate={navigate} />}
          />

          <Route path="/add-card" element={<AddCard navigate={navigate} />} />

          <Route
            path="/family-members"
            element={<Members navigate={navigate} />}
          />
          <Route
            path="/add-family-members"
            element={<AddFamilyMembers navigate={navigate} />}
          />
          <Route
            path="/add-family-members/:memberId"
            element={<AddFamilyMembers navigate={navigate} update />}
          />
          <Route path="/chat/:recipientId" element={<ChatTab navigate={navigate} />} />
          <Route
            path="/video-call"
            element={<VideoCall navigate={navigate} />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard navigate={navigate} />}
          />

          <Route
            path="/settings/personal-details"
            element={<PersonalDetails navigate={navigate} />}
          />

          <Route
            path="/payment-method"
            element={<Paymentmethod navigate={navigate} />}
          />
          {/* <Route
            path="/stripe-payment"
            element={<StripePayment navigate={navigate} />}
          /> */}

          <Route
            path="/add-medicine-reminder"
            element={<AddMedicineReminder navigate={navigate} />}
          />
          <Route
            path="/doctors-filter"
            element={<DocotorsFilter navigate={navigate} />}
          />
          <Route
            path="/prescribed-medicines"
            element={<PrescribedMedicines navigate={navigate} />}
          />
          <Route path="/faq" element={<FAQ navigate={navigate} />} />
          <Route path="/feedback" element={<Feedbacks navigate={navigate} />} />
          <Route path="/user-guide" element={<UserGuide navigate={navigate} />} />
          <Route path="/terms-and-policies" element={<TermPolicies navigate={navigate} />} />
          <Route path="/troubleshooting-guide" element={<TroubleShootGuide navigate={navigate} />} />


          <Route
            path="/medicines-list"
            element={<MedicinesList navigate={navigate} />}
          />
          <Route
            path="/password-and-security"
            element={<PasswordSecurity navigate={navigate} />}
          />
          <Route
            path="/change-password"
            element={<ChangePassword navigate={navigate} />}
          />

          <Route
            path="/settings/medoczen-pro"
            element={<MedoczenPro navigate={navigate} />}
          />

          <Route
            path="/settings/notification-settings"
            element={<NotificationSettings navigate={navigate} />}
          />
          <Route path="/settings/notification-settings/:heading" element={<NotificationSettingsDetails navigate={navigate} />} />

          <Route
            path="/prescription"
            element={<PrescriptionList navigate={navigate} />}
          />


        </Routes>
      </div>

      {/* Conditionally render BottomNav based on showBottomNav state */}
      {showBottomNav && <BottomNav />}

      <ToastMessage />
    </div>
  );
}

export default App;
