import React from "react";
import { Divider, List, ListItem, Typography } from "@mui/material";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function NotificationSettings() {
  const navigate = useNavigate();
  const handleClick = (heading) => {
    navigate(`/settings/notification-settings/${heading}`);
  };
  const headings = ["account", "appointment", "payment", "subscription"];

  return (
    <>
      <HeaderWrapper title="Notification Settings" path="settings" />
      <form className=" ">
        <Typography variant="subtitle2" p={2} fontWeight={400}>
          Manage your notification preferences across various areas.
        </Typography>

        <List>
          {headings.map((heading, index) => (
            <React.Fragment key={heading}>
              <ListItem button sx={{ py: 2 }} onClick={() => handleClick(heading)}>
                <Typography
                  variant="subtitle1"
                  className=" capitalize"
                  fontSize={16}
                  fontWeight={500}
                >
                  {heading}
                </Typography>
                <div className="ml-auto">
                  <IoIosArrowForward size={20} />
                </div>
              </ListItem>
              {index < headings.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </form>
    </>
  );
}
