import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { useDispatch, useSelector } from "react-redux";

import tabletPng from "@/../../public/assets/images/medicines/tablets.png";
import { getPrescribedMedicines, selectPrescribedMedicines } from "./store/AppointmentSlice";

// This is the main component for displaying pharmaceuticals
export default function Pharmaceuticals({ metadata }) {
  // State for loading status
  const [loading, setLoading] = useState(true);
  // Redux dispatch function
  const dispatch = useDispatch();
  // Redux state for medications
  const medications = useSelector(selectPrescribedMedicines);

  // Function to fetch data
  const fetchData = async () => {
    setLoading(true);
    try {
      // console.log("Fetching", metadata);
      // Dispatch action to get prescribed medicines
      await dispatch(
        getPrescribedMedicines({
          appointment_id: metadata.appointment_id,
          prescription_id: metadata.prescription_id,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // UseEffect to fetch data when prescription_id changes
  useEffect(() => {
    if (metadata.prescription_id) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [metadata.prescription_id]);

  // Main render function
  return (
    <>
      <div className=" flex flex-col gap-3">
        {loading
          ? // Display loading skeletons
            Array(4)
              .fill()
              .map((_, index) => (
                <div className="w-full px-[1rem]">
                  <Skeleton key={index} variant="rectangular" sx={{ width: "100%" }} height={80} />
                </div>
              ))
          : // Display medications
            medications?.map((medication) => (
              <div
                key={medication.id}
                className="flex w-fit h-[147px] border-[1px] p-[10px]  rounded-xl shadow-lg"
              >
                <div className=" w-[50px] me-[1rem]">
                  <img src={tabletPng} className="w-full h-[41px] object-cover" alt="" />{" "}
                </div>
                <div>
                  <h1 className="text-[14px] font-semibold ">
                    {medication?.medication_name} {""}
                  </h1>
                  <p className="text-[14px] font-[400] lowercase">
                    {medication.dosage}, {medication?.frequency} for {medication?.duration} at{" "}
                    {medication?.time_of_day} {medication?.to_be_taken}
                  </p>
                  <div className="flex justify-start gap-[1rem] items-center  mt-[10px]">
                    <button className="w-[104px] h-[32px] border-[1px]  border-[#b4aeae] rounded-[5px] text-primary text-[12px] font-[400] ">
                      Order Medicine
                    </button>
                    <button className="w-[104px] h-[32px] border-[1px]  border-[#b4aeae] rounded-[5px] text-primary text-[12px] font-[400] ">
                      Set Reminder
                    </button>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
}
