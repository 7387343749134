import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  Switch,
  Typography,
} from "@mui/material";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import {
  getNotificationSetting,
  selectNotificationSetting,
  selectNotificationSettingLoading,
  updateNotificationSetting,
} from "./store/NotificationSettingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { useParams } from "react-router-dom";
import { EmailOutlined, NotificationsOutlined, SmsOutlined } from "@mui/icons-material";

export default function NotificationSettingsDetails() {
  const { heading } = useParams();
  const { control, watch, getValues, reset } = useForm({});

  const notificationSetting = useSelector(selectNotificationSetting);

  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentOption, setcurrentOption] = useState("");
  const dispatch = useDispatch();

  // Fetch data on component mount
  useEffect(() => {
    dispatch(getNotificationSetting(heading)).then(() => setLoading(false));
  }, [dispatch]);

  // Reset form values when notification settings are fetched
  useEffect(() => {
    if (notificationSetting) {
      reset(notificationSetting);
    }
  }, [notificationSetting, reset]);

  // Watch values for debugging or displaying current state (optional)
  const values = watch();

  // Function to handle save action when a switch is toggled
  const saveSettings = (module, option, value) => {
    setIsSaving(true);
    console.log("saveSettings", heading, module, option, value);

    const body = {
      heading,
      module,
      option,
      value,
    };

    dispatch(updateNotificationSetting(body)).then(() => {
      dispatch(getNotificationSetting(heading)).then(() => {
        setIsSaving(false);
        // handleDialogClose();
      });
    });
  };

  const handleDialogOpen = (heading) => {
    setcurrentOption(heading);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setcurrentOption("");
  };

  // Extract headings from notificationSetting keys
  const headings = notificationSetting ? Object.keys(notificationSetting) : [];

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeaderWrapper
        title={`${heading.replace(/_/g, " ")} Notifications`}
        path="settings/notification-settings"
      />
      <form>
        <Typography variant="subtitle2" p={2} fontWeight={400}>
          Manage your notification preferences across various areas.
        </Typography>

        <List>
          {headings.map((heading, index) => (
            <React.Fragment key={heading}>
              <ListItem button sx={{ py: 2 }} onClick={() => handleDialogOpen(heading)}>
                <Typography
                  variant="subtitle1"
                  className="capitalize"
                  fontSize={16}
                  fontWeight={500}
                >
                  {heading.replace(/_/g, " ")} {/* Convert snake_case to readable text */}
                </Typography>
                <div className="ml-auto">
                  <IoIosArrowForward size={20} />
                </div>
              </ListItem>
              {index < headings.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>

        <Dialog fullScreen open={open} onClose={handleDialogClose}>
          <Backdrop open={isSaving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <HeaderWrapper
            action={handleDialogClose}
            title={`${currentOption.replace(/_/g, " ")}`}
          ></HeaderWrapper>
          {/* <Typography variant="h6" p={1} pl={3} fontWeight={500}>
            Choose where get notified
          </Typography> */}
          <div className="p-4 space-y-4">
            {/* Push Notifications */}
            <div className="flex justify-start items-center">
              <NotificationsOutlined fontSize="large" />
              <div className="ml-5 mr-auto">
                <Typography variant="subtitle1">Push Notifications</Typography>
                <Typography variant="body2" color="text.secondary">
                  Pushed to your device immediately
                </Typography>
              </div>
              <Controller
                name={`${currentOption}.push`}
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value || false}
                    onChange={(e) => saveSettings(`${currentOption}`, `push`, e.target.checked)}
                  />
                )}
              />
            </div>
            {/* Email Notifications */}
            <div className="flex justify-start  items-center">
              <EmailOutlined fontSize="large" />
              <div className="ml-5 mr-auto">
                <Typography variant="subtitle1">Email Notifications</Typography>
                <Typography variant="body2" color="text.secondary">
                  Sent on your primary email
                </Typography>
              </div>

              <Controller
                name={`${currentOption}.email`}
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value || false}
                    onChange={(e) => saveSettings(`${currentOption}`, `email`, e.target.checked)}
                  />
                )}
              />
            </div>
            <Divider />

            {/* SMS Notifications */}
            <div className="flex justify-start items-center">
              <SmsOutlined fontSize="large" />
              <div className="ml-5 mr-auto">
                <Typography variant="subtitle1">SMS Notifications</Typography>
                <Typography variant="body2" color="text.secondary">
                  Sent on your primary mobile
                </Typography>
              </div>
              <Controller
                name={`${currentOption}.sms`}
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value || false}
                    onChange={(e) => saveSettings(`${currentOption}`, `sms`, e.target.checked)}
                  />
                )}
              />
            </div>
          </div>
        </Dialog>
      </form>
    </>
  );
}
