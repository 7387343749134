import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk actions for fetching notificationSetting data
export const getNotificationSetting = createAsyncThunk(
    "notificationSetting/getNotificationSetting",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`notification-settings/${params}`);
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
            return rejectWithValue(errorMessage);
        }
    }
);


export const updateNotificationSetting = createAsyncThunk(
    "notificationSetting/updateNotificationSetting",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.put(`notification-settings`, params);
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
            return rejectWithValue(errorMessage);
        }
    }
);

// Slice definition
export const notificationSettingSlice = createSlice({
    name: "notificationSetting",
    initialState: {
        notificationSetting: null,
    },
    reducers: {
        resetNotificationSettingValues: (state) => {
            state.notificationSetting = null;
            state.loading = false;
            state.error = null;

        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getNotificationSetting.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getNotificationSetting.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationSetting = action.payload;
            })
            .addCase(getNotificationSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(updateNotificationSetting.pending, (state) => {
                state.loading = true;
                state.error = null;

            }).addCase(updateNotificationSetting.fulfilled, (state, action) => {
                state.loading = false;

            }).addCase(updateNotificationSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })



            ;
    },
});

// Selectors
export const selectNotificationSettingLoading = (state) => state.notificationSetting.notificationSettingSlice?.loading;
export const selectError = (state) => state.notificationSetting.notificationSettingSlice?.error;
export const selectNotificationSetting = (state) => state.notificationSetting.notificationSettingSlice?.notificationSetting;

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = notificationSettingSlice.name;
export const { resetNotificationSettingValues } = notificationSettingSlice.actions;

export default notificationSettingSlice.reducer;
